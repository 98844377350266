body {
    background-color: #fff !important;
    min-height: 100vh !important;
    margin: 0 auto !important;
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input:-webkit-autofill {
    -webkit-transition-delay: 9999999s;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide-exit, .slideback-exit {
    z-index: 1;
}

.slide-enter, -slideback-enter {
    z-index: 10;
}

.slide-enter {
    transform: translate3d(100%, 0, 0);
}

.slideback-enter {
    transform: translate3d(-100%, 0, 0);
}

.slide-exit {
    transform: translate3d(-120%, 0, 0);
}

.slideback-exit {
    transform: translate3d(120%, 0, 0);
}

.slide-enter.slide-enter-active, .slideback-enter.slideback-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all 200ms ease-in-out;
}